<template>
  <div class="page-main-bg half-circle-bg">
    <v-container class="main-container">
      <figure class="position-absolute half-circle-1">
        <img :src="halfcircle1" loading="lazy" />
      </figure>
      <figure class="position-absolute half-circle-2">
        <img :src="halfcircle2" loading="lazy" />
      </figure>
      <figure
        class="position-absolute z-index-n1"
        style="top: 2rem; left: 15rem; width: 10rem; height: 10rem"
      >
        <img class="img-fluid" :src="dots" loading="lazy" />
      </figure>

      <v-toolbar
        height="80px"
        color="elevation-0"
        id="page-tollbar"
        class="my-5 narrow-toolbar"
      >
        <div class="page-title">
          <v-toolbar-title>المهام على النتيجة</v-toolbar-title>
        </div>

        <v-spacer></v-spacer>

        <v-btn
          rounded
          large
          text
          color="info"
          class="ml-2"
          to="/dashboard/task-manager"
        >
          <v-icon>mdi-arrow-right</v-icon>
          <span class="tf">عودة لقائمة المهام</span>
        </v-btn>

        <v-btn large rounded color="success" @click.prevent="openAddDialog">
          <v-icon>mdi-plus</v-icon>
          <span class="tf">إضافة مهمة جديدة</span>
        </v-btn>
      </v-toolbar>

      <v-row class="fill-height pb-6">
        <v-col class="pt-0">
          <v-sheet height="64">
            <v-toolbar flat color="secondary" class="white--text">
              <v-btn
                solo
                class="primary--text ml-4 elevation-0"
                @click="setToday"
              >
                <span class="tf">اليوم</span>
              </v-btn>
              <v-btn fab text small color="white" @click="prev">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn fab text small color="white" class="ml-4" @click="next">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">{{
                $refs.calendar.title
              }}</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    solo
                    class="primary--text elevation-0"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <span class="tf">{{ typeToLabel[type] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>شهر</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>اسبوع</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = '4day'">
                    <v-list-item-title>٤ أيام</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>يوم</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>

          <v-sheet>
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @change="updateRange"
            ></v-calendar>
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="sanitize(selectedEvent.name)"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="sanitize(selectedEvent.details)"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false"
                    >الغاء</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <!-- Add New Unit Modal -->
    <AddNotificationModal
      :dialogState="dialogState"
      @changeDialogState="dialogState = $event"
      @closed-add-dialog="dialogState = false"
    ></AddNotificationModal>
  </div>
</template>

<script>
import config from '@/config'
import { mapMutations } from 'vuex'
import '@/styles/material-dashboard/_properties.scss'
import AddNotificationModal from '@/components/modals/AddNotificationModal.vue'
import sanitizers from '@/mixins/sanitizers.js'

export default {
  mixins: [sanitizers],
  components: {
    AddNotificationModal
  },
  data: () => {
    return {
      halfcircle1: require('@/assets/img/half-circle-1.svg'),
      halfcircle2: require('@/assets/img/half-circle-2.svg'),
      dots: require('@/assets/img/dots-3.svg'),
      dots2: require('@/assets/img/dots-1.svg'),
      dialogState: false,
      // type: "month",
      // types: [
      //   { text: "شهر", value: "month" },
      //   { text: "اسبوع", value: "week" },
      //   { text: "يوم", value: "day" },
      //   { text: "اربعة أيام", value: "4day" },
      // ],
      // mode: "stack",
      // modes: [
      //   { text: "مهام متداخلة", value: "stack" },
      //   { text: "أعمدة", value: "column" },
      // ],
      // weekday: [6, 0, 1, 2, 3, 4, 5],
      // weekdays: [
      //   { text: "من السبت إلى الجمعة", value: [6, 0, 1, 2, 3, 4, 5] },
      //   { text: "من السبت إلى الخميس", value: [6, 0, 1, 2, 3, 4] },
      //   { text: "من الأحد إلى الخميس", value: [0, 1, 2, 3, 4] },
      //   { text: "الجمعة والسبت", value: [5, 6] },
      // ],
      // value: "",
      // events: [],
      colors: [
        'blue',
        'indigo',
        'deep-purple',
        'cyan',
        'green',
        'orange',
        'grey darken-1'
      ],
      names: [
        'اجتماع الشركة',
        'أجازة',
        'سفر',
        'زيارة',
        'حدث',
        'حلفة',
        'ضريبة القيمة المضافة',
        'السلم الوظيفي في الشركة'
      ],
      focus: '',
      type: 'month',
      typeToLabel: {
        month: 'شهر',
        week: 'اسبوع',
        day: 'يوم',
        '4day': '٤ أيام'
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: []
    }
  },
  mounted() {
    this.$refs.calendar.checkChange()
  },
  computed: {
    properties() {
      return config.properties
    }
  },
  methods: {
    ...mapMutations('appState', ['addNotification', 'toggleDrawer']),
    openAddDialog() {
      this.dialogState = true
    },

    // getEvents({ start, end }) {
    //   const events = [];

    //   const min = new Date(`${start.date}T00:00:00`);
    //   const max = new Date(`${end.date}T23:59:59`);
    //   const days = (max.getTime() - min.getTime()) / 86400000;
    //   const eventCount = this.rnd(days, days + 20);

    //   for (let i = 0; i < eventCount; i++) {
    //     const allDay = this.rnd(0, 3) === 0;
    //     const firstTimestamp = this.rnd(min.getTime(), max.getTime());
    //     const first = new Date(firstTimestamp - (firstTimestamp % 900000));
    //     const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000;
    //     const second = new Date(first.getTime() + secondTimestamp);

    //     events.push({
    //       name: this.names[this.rnd(0, this.names.length - 1)],
    //       start: first,
    //       end: second,
    //       color: this.colors[this.rnd(0, this.colors.length - 1)],
    //       timed: !allDay,
    //     });
    //   }

    //   this.events = events;
    // },
    // getEventColor(event) {
    //   return event.color;
    // },
    // rnd(a, b) {
    //   return Math.floor((b - a + 1) * Math.random()) + a;
    // },
    viewDay({ date }) {
      this.focus = date
      this.type = 'day'
    },
    getEventColor(event) {
      return event.color
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => {
          this.selectedOpen = true
        }, 10)
      }
      if (this.selectedOpen) {
        this.selectedOpen = false
        setTimeout(open, 10)
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    updateRange({ start, end }) {
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)

        events.push({
          name: this.names[this.rnd(0, this.names.length - 1)],
          start: first,
          end: second,
          color: this.colors[this.rnd(0, this.colors.length - 1)],
          timed: !allDay
        })
      }

      this.events = events
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  },
  metaInfo() {
    return {
      title: 'قائمة العقارات'
    }
  }
}
</script>

<style lang="scss">
.v-calendar-daily__head {
  margin-right: 0 !important;
  margin-left: 0 !important;

  .v-calendar-daily__intervals-head:after {
    background: linear-gradient(90deg, #e0e0e0, transparent) !important;
  }
}

.v-calendar-daily__interval {
  text-align: left;
  padding-left: 4px;
  padding-right: 0;

  &:after {
    right: auto;
    left: 0;
  }
}

.v-calendar .v-event,
.v-calendar .v-event-more {
  > div {
    margin-top: -2px;
    padding-right: 5px;
  }
}

.v-calendar-daily__scroll-area {
  overflow-y: auto;
}
</style>
